<template>
  <div class="pm-dashboard my-10 px-2">
    <div class="d-flex" :style="{ position: 'relative', flexWrap: 'wrap' }">
      <!-- left -->

      <!-- new task -->
      <div class="text-center">
        <span class="overline"> New </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            :loading="dropdownsLoading"
            @click="handleForm({})"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="ml-2 text-center">
          <span class="overline"> Add </span>
          <div>
            <v-btn
                min-width="40px"
                height="38px"
                color="#494949"
                class="px-0"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <v-dialog
          content-class="filters"
          transition="dialog-top-transition"
          max-width="1500px"
          v-model="filtersDialog"
          hide-overlay
          persistent
          no-click-animation
        >
          <v-card
            class="flex-column flex-wrap pa-4 w-100"
          >
            <v-container class="tasks-filters ma-0 w-100" fluid>
              <v-row>
                <p class="tasks-filters__title mb-0">Tasks filters</p>
              </v-row>
              <v-row>
                <v-autocomplete
                  class="pm-dashboard__filters--month-selector selector mr-3 filter-item-bg"
                  :items="monthOptions"
                  v-model="ppm"
                  outlined
                  dense
                  hide-details
                  label="Pay Month"
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppm', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--year-selector selector mr-3 filter-item-bg"
                  :items="yearOptions"
                  v-model="ppy"
                  label="Pay Year"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppy', $event)"
                >
                </v-autocomplete>
                <div class="text-center mr-3">
                  <div class="d-flex align-end">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      solo-inverted
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedRequestDate"
                          class="pm-dashboard__filters--date-selector selector filter-item-bg"
                          label="Request Date"
                          outlined
                          dense
                          readonly
                          hide-details
                          clearable
                          :disabled="showTableLoader || isTableUpdating"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilter('requestDate')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterRequestDate"
                        @input="handleFilterQuery('requestDate', $event)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="text-center mr-3">
                  <div class="d-flex align-end">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      solo-inverted
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedDeliveryDateFrom"
                          class="pm-dashboard__filters--ddfrom-selector selector filter-item-bg"
                          label="Delivery Date (from)"
                          outlined
                          dense
                          readonly
                          hide-details
                          clearable
                          :disabled="showTableLoader || isTableUpdating"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilter('deliveryDateFrom')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterDeliveryDateFrom"
                        @input="handleFilterQuery('deliveryDateFrom', $event)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="text-center mr-3">
                  <div class="d-flex align-end">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      solo-inverted
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedDeliveryDateTo"
                          class="pm-dashboard__filters--ddto-selector selector filter-item-bg"
                          label="Delivery Date (to)"
                          outlined
                          dense
                          readonly
                          hide-details
                          clearable
                          :disabled="showTableLoader || isTableUpdating"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilter('deliveryDateTo')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterDeliveryDateTo"
                        @input="handleFilterQuery('deliveryDateTo', $event)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <v-autocomplete
                  class="pm-dashboard__filters--project-selector selector mr-3 filter-item-bg "
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Project"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  :class="{ laptopSmaller }"
                  class="pm-dashboard__filters--person-selector selector mr-3 filter-item-bg"
                  :items="employees"
                  :item-text="fullName"
                  return-object
                  v-model="filterPerson"
                  label="DRI"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Person', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--action-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Action Required"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--status-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="taskStatuses"
                  v-model="filterStatus"
                  label="Task Status"
                  item-text="value"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Status', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--checked-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Checked"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
              </v-row>
              <v-row class="mt-8">
                <p class="tasks-filters__title mb-0">Assignments filters</p>
              </v-row>
              <v-row>
                <v-autocomplete
                  class="pm-dashboard__filters--month-selector selector mr-3 filter-item-bg"
                  :items="monthOptions"
                  v-model="ppm"
                  outlined
                  dense
                  hide-details
                  label="Pay Month"
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppm', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--year-selector selector mr-3 filter-item-bg"
                  :items="yearOptions"
                  v-model="ppy"
                  label="Pay Year"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  @change="handleFilterQuery('ppy', $event)"
                >
                </v-autocomplete>
                <div class="text-center mr-3">
                  <div class="d-flex align-end">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      solo-inverted
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedRequestDate"
                          class="pm-dashboard__filters--date-selector selector filter-item-bg"
                          label="Request Date"
                          outlined
                          dense
                          readonly
                          hide-details
                          clearable
                          :disabled="showTableLoader || isTableUpdating"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilter('RequestDate')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterRequestDate"
                        @input="handleFilterQuery('RequestDate', $event)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="text-center mr-3">
                  <div class="d-flex align-end">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      solo-inverted
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedRequestDate"
                          class="pm-dashboard__filters--ddto-selector selector filter-item-bg"
                          label="Delivery Date (to)"
                          outlined
                          dense
                          readonly
                          hide-details
                          clearable
                          :disabled="showTableLoader || isTableUpdating"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilter('RequestDate')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterRequestDate"
                        @input="handleFilterQuery('RequestDate', $event)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <v-autocomplete
                  class="pm-dashboard__filters--geo-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="GEO"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--fileLocation-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="File Location"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--person-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Person"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--role-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Role"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--media-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Media Type"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
                <v-autocomplete
                  class="pm-dashboard__filters--requestType-selector selector mr-3 filter-item-bg"
                  :class="{ laptop }"
                  :items="projectsList"
                  label="Request Type"
                  item-text="name"
                  return-object
                  v-model="filterProject"
                  outlined
                  dense
                  hide-details
                  :disabled="showTableLoader || isTableUpdating"
                  :loading="dropdownsLoading"
                  @input="handleFilterQuery('Project', $event)"
                ></v-autocomplete>
              </v-row>
            </v-container>

            <v-card-actions class="ml-auto justify-end pa-0 pt-2 mt-2">
              <v-btn
                color="#FF5252"
                outlined
                @click="clearFilter(false)"
                >Clear Filters</v-btn
              >
              <v-btn 
                color="#000000DE" 
                outlined
                @click="filtersDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!-- clear filters -->
      <div class="text-center ml-1">
        <span class="overline"> Clear </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            :disabled="showTableLoader || isTableUpdating"
            @click="clearFilter(false)"
          >
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex">
        <!-- period filter -->
        <div class="ml-lg-3 ml-md-1 d-flex align-center">
          <div class="d-flex pp-magin-bottom">
            <v-autocomplete
              class="pm-dashboard__month-selector selector mr-1 filter-item-bg"
              :items="monthOptions"
              v-model="ppm"
              outlined
              dense
              hide-details
              label="Month"
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppm', $event)"
            ></v-autocomplete>
            <v-autocomplete
              class="pm-dashboard__year-selector selector filter-item-bg"
              :items="yearOptions"
              v-model="ppy"
              label="Year"
              outlined
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              @change="handleFilterQuery('ppy', $event)"
            >
            </v-autocomplete>
          </div>
        </div>
        <!-- project filter -->
        <div class="ml-lg-2 ml-md-1 text-center project-margin">
          <div>
            <v-autocomplete
              class="pm-dashboard__project-selector selector filter-item-bg"
              :class="{ laptop }"
              :items="projectsList"
              item-text="name"
              return-object
              v-model="filterProject"
              label="Project"
              outlined
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Project', $event)"
            ></v-autocomplete>
          </div>
        </div>
        <!-- request date filter -->
        <div class="ml-lg-2 ml-md-1 text-center project-margin">
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedRequestDate"
                  class="pm-dashboard__date-selector selector filter-item-bg"
                  label="Request Date"
                  outlined
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('RequestDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterRequestDate"
                @input="handleFilterQuery('RequestDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <!-- person filter -->
        <div class="ml-lg-2 ml-md-1 text-center project-margin">
          <div class="d-flex mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__person-selector selector filter-item-bg"
              :items="employees"
              :item-text="fullName"
              return-object
              v-model="filterPerson"
              label="DRI"
              outlined
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Person', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <!-- task status filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center project-margin">
          <div class="mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__main-selector selector filter-item-bg"
              :items="taskStatuses"
              v-model="filterStatus"
              item-text="value"
              label="Task Status"
              outlined
              dense
              hide-details
              :disabled="showTableLoader || isTableUpdating"
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Status', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </transition>
      <!-- delivery date filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center project-margin">
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDeliveryDate"
                  class="pm-dashboard__date-selector selector filter-item-bg"
                  label="Delivery Date"
                  outlined
                  dense
                  readonly
                  hide-details
                  clearable
                  :disabled="showTableLoader || isTableUpdating"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('deliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('deliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </transition>

      <!-- right -->
      <!-- <div class="d-flex flex-column justify-start align-end my-0 ml-auto">
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="pm-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
              :disabled="showTableLoader || isTableUpdating"
              @click:clear="applySearch(true)"
              @keydown.enter="applySearch(false)"
            />
          </v-expand-x-transition>
        </div>
      </div> -->
    </div>
    <!-- table -->
    <v-card class="pm-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader || dropdownsLoading"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <PMDashboardTable
        ref="pmDashboardTable"
        v-if="tableData && !showTableLoader && !dropdownsLoading"
        :tableData="tableData"
        :serverItemsLength="serverItemsLength"
        :isTableUpdating="isTableUpdating"
        :leadPM="leadPM"
        :taskStatuses="taskStatuses"
        :employees="employees"
        :dropdownOptionsLoading="dropdownsLoading"
        @call-edit-form="handleForm"
        @get-table-data="getTableData"
        @refresh-request="refreshTableData"
      />
    </v-card>
    <!-- task form -->
    <v-dialog
      v-model="taskForm"
      max-width="1720"
      persistent
      @click:outside="closeTaskForm"
      @keydown.esc="closeTaskForm"
      ref="taskForm"
      class="pm-form"
    >
      <PMDashboardForm
        v-if="taskForm"
        :mediaTypes="mediaTypes"
        :taskStatuses="taskStatuses"
        :priorities="priorities"
        :employees="employees"
        :projectsList="projectsList"
        :itemToEdit="itemToEdit"
        :leadPM="leadPM"
        :openConfirmationModal="openConfirmationModal"
        @close-task-form="handleFormClosing"
      />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  getTasks,
  getAllStaff,
  getAllProjects,
  getEnum,
  leadPMEmail,
} from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
// components
import PMDashboardTable from "@/components/PMDashboard/PMDashboardTable";
import PMDashboardForm from "@/components/PMDashboard/PMDashboardForm.vue";

export default {
  name: "PMDashboard",
  components: {
    PMDashboardTable,
    PMDashboardForm,
  },
  data() {
    return {
      taskFormKey: 0,
      filtersDialog: false,
      showTableLoader: true,
      isTableUpdating: false,
      openConfirmationModal: false,
      tableData: null,
      serverItemsLength: 0,
      filterDeliveryDate: null,
      filterRequestDate: null,
      filterSearch: null,
      filterDeliveryDateFrom: null,
      filterDeliveryDateTo: null,
      expandMenu: false,
      dropdownsLoading: true,
      employees: [],
      filterPerson: null,
      taskStatuses: [],
      filterStatus: null,
      priorities: [],
      mediaTypes: [],
      projectsList: [],
      filterProject: null,
      taskForm: false,
      itemToEdit: {},
      ppm: null,
      ppy: null,
      itemsPerPage: 50,
      page: 0,
      sortBy: "requestDate&sort=requestTime",
      sorting: "desc",
      leadPM: {},
    };
  },
  mixins: [customBreakpoint, timeUnits],
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    formattedRequestDate: {
      get() {
        return this.formatPickerDate(this.filterRequestDate);
      },
      set() {
        this.filterRequestDate = null;
      },
    },
    formattedDeliveryDateFrom: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDateFrom);
      },
      set() {
        this.filterDeliveryDateFrom = null;
      },
    },
    formattedDeliveryDateTo: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDateTo);
      },
      set() {
        this.filterDeliveryDateTo = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.filterDeliveryDate);
      },
      set() {
        this.filterDeliveryDate = null;
      },
    },
    query() {
      return {
        page: this.page,
        size: this.itemsPerPage,
        sort: `${this.sortBy},${this.sorting}`,
        search: this.filterSearch,
        requestDate: this.filterRequestDate,
        deliveryDate: this.filterDeliveryDate,
        ppy: this.ppy,
        ppm: this.ppm?.toUpperCase(),
        projectId: this.filterProject?.id,
        driId: this.filterPerson?.user?.id,
        taskStatus: this.filterStatus ?? "!Archived",
      };
    },
  },
  watch: {
    taskForm(val) {
      if (val) {
        // When the popup appears
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden'; // This line is added
      } else {
        // When the popup disappears
        document.body.style.overflow = '';
        document.documentElement.style.overflow = ''; // This line is added
      }
    },
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleDropdowns();
    this.handleQuery();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    ...mapActions("staff", ["aSetStaff"]),
    ...mapActions("pmDashboard", ["aSetIsEditing"]),
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    getTableData(hardReload) {
      if (hardReload) this.showTableLoader = true;
      else this.isTableUpdating = true;
      getTasks({ query: this.query }).then((resp) => {
        if (resp?.status > 204) {
          this.tableData = [];
          this.handleFlash({ response: resp, show: true });
        } else {
          this.tableData = resp.content.map((el, i) => ({
            ...el,
            index: i,
          }));
          this.tableData.forEach((el) => {
            const statusesList = ['Requested', 'Confirmed', 'Assigned', 'In Progress', 'On Hold', 'Delivered', 'Cancelled']
            const statuses = statusesList.map((elem) => ({ status: elem, itemsNumber: 0 }));
            statuses.push({ status: 'Total', itemsNumber: 0 });
            el.assignmentResponses.forEach((item) => {
              statuses.find(status => status.status === item.assignmentDetails.assignmentStatus).itemsNumber++;
              statuses.find(status => status.status === 'Total').itemsNumber++;
            })
            el.preparedAssignments = statuses;
          })
        }
        this.serverItemsLength = resp?.totalElements ?? 0;
        this.showTableLoader = this.isTableUpdating = false;
      });
    },
    refreshTableData(options) {
      const handleSort = () => {
        const sort = options.sortBy[0];
        return sort === undefined || sort === "sortParams" ? this.sortBy : sort;
      };
      this.itemsPerPage = options.itemsPerPage;
      this.page = options.page - 1;
      this.sortBy = handleSort();
      this.sorting =
        options.sortDesc[0] === undefined
          ? "desc"
          : options.sortDesc[0]
          ? "desc"
          : "asc";
      this.getTableData();
    },
    handleFilterQuery(param, data) {
      const query = {
        ...this.$route.query,
        [param]:
          param === "Person" && data
            ? `${data.user.firstName} ${data.user.lastName}`
            : param === "Project" && data
            ? data?.name
            : data,
      };

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
        data;

      if (param === "ppm" && !this.ppy) return;

      if (this.$refs.pmDashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.pmDashboardTable.options.page = 1;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        query = {};
        this.filterProject =
          this.filterPerson =
          this.filterStatus =
          this.filterSearch =
          this.filterDeliveryDateFrom =
          this.filterDeliveryDateTo =
          this.ppm =
          this.ppy =
            null;
      }

      if (!date) this.filterRequestDate = this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (this.$refs.pmDashboardTable?.options?.page === 1) this.getTableData();
      else this.$refs.pmDashboardTable.options.page = 1;
    },
    handleDropdowns() {
      Promise.all([
        getAllProjects(),
        getEnum("MediaType"),
        getEnum("Priority"),
        getEnum("TaskStatus"),
        getAllStaff(),
      ])
        .then((resp) => {
          [this.projectsList, this.mediaTypes, this.priorities] = resp;
          this.projectsList.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.taskStatuses = resp[3].map((el) => ({
            value: el,
            color: STATUS_COLORS.find((color) => color.statuses.includes(el))
              ?.value,
          }));
          this.aSetStaff(resp[4]);
          this.employees =
            resp[4].filter(
              (el) =>
                el.user.userRole === "ADMIN" || el.user.userRole === "MANAGER"
            ) ?? [];
          this.leadPM = this.employees.find(
            (el) => el.user.email === leadPMEmail
          );
          if (this.$route.query?.Person || this.$route.query?.Project) {
            if (this.$route.query?.Person)
              this.filterPerson = this.employees.find(
                (el) =>
                  this.$route.query.Person.includes(el.user.firstName) &&
                  this.$route.query.Person.includes(el.user.lastName)
              );
            if (this.$route.query?.Project)
              this.filterProject = this.projectsList.find(
                (el) => this.$route.query.Project === el.name
              );
            this.getTableData(true);
          }
          if (this.$route.query?.openTaskForm) {
            this.handleForm({ id: this.$route.query?.openTaskForm });
          }
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      let query = { ...this.$route.query };
      if (_.isEmpty(query)) {
        this.ppm = this.$moment().format("MMMM");
        this.ppy = this.$moment().format("YYYY");
        query = {
          ppm: this.ppm,
          ppy: this.ppy,
        };
      } else {
        Object.keys(query).forEach((param) => {
          this[param === "ppy" || param === "ppm" ? param : `filter${param}`] =
            query[param];
        });
      }
      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!query?.Person && !query?.Project) this.getTableData(true);
    },
    applySearch(reset) {
      if (reset) this.filterSearch = null;
      this.handleFilterQuery("Search", this.filterSearch);
    },
    handleForm(tableRow) {
      tableRow.id ? this.aSetIsEditing(true) : this.aSetIsEditing(false);
      this.itemToEdit = { ...tableRow };
      let query = { ...this.$route.query };
      query = { ...query, openTaskForm: tableRow.id };
      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {});
      this.taskForm = true;
    },
    handleFormClosing() {
      let query = { ...this.$route.query };
      query && query.openTaskForm && delete query.openTaskForm;
      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {});
      this.taskForm = false;
    },
    closeTaskForm() {
      this.openConfirmationModal = !this.openConfirmationModal;
    }
  },
};
</script>

<style lang="scss" scoped>
.pm-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__filters {
    &--month-selector {
      width: 134px;
      margin-top: 15px;
    }
    &--year-selector {
      width: 116px;
      margin-top: 15px;
    }
    &--project-selector {
      width: 167px;
      margin-top: 15px;
    }
    &--date-selector {
      width: 156px;
      margin-top: 15px;
    }
    &--ddfrom-selector {
      width: 178px;
      margin-top: 15px;
    }
    &--ddto-selector {
      width: 178px;
      margin-top: 15px;
    }
    &--person-selector {
      width: 156px;
      max-width: 156px;
      margin-top: 15px;
    }
    &--action-selector {
      width: 166px;
      max-width: 166px;
      margin-top: 15px;
    }
    &--status-selector {
      width: 156px;
      max-width: 156px;
      margin-top: 15px;
    }
    &--checked-selector {
      width: 116px;
      max-width: 116px;
      margin-top: 15px;
    }
    &--geo-selector {
      width: 156px;
      max-width: 156px;
      margin-top: 15px;
    }
    &--fileLocation-selector {
      width: 144px;
      max-width: 144px;
      margin-top: 15px;
    }
    &--person-selector {
      width: 156px;
      max-width: 156px;
      margin-top: 15px;
    }
    &--role-selector {
      width: 156px;
      max-width: 156px;
      margin-top: 15px;
    }
    &--media-selector {
      width: 138px;
      max-width: 138px;
      margin-top: 15px;
    }
    &--requestType-selector {
      width: 138px;
      max-width: 138px;
      margin-top: 15px;
    }
  }

  &__month-selector {
    width: 120px;
    margin-top: 17px;
  }

  &__year-selector {
    width: 80px;
    margin-top: 17px;
  }

  &__project-selector {
    width: 200px;
    &.laptop {
      width: 180px;
    }
  }

  &__date-selector {
    width: 130px;
  }

  &__person-selector {
    width: 200px;
    &.laptopSmaller {
      width: 180px;
    }
  }

  &__main-selector {
    width: 150px;
    &.laptopSmaller {
      width: 140px;
    }
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.pp-magin-bottom {
  margin-bottom: 1px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.tasks-filters {
  &__title {
    color: #000000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
}

.project-margin {
  margin-top: 32px !important;
}

.filter-item-bg {
  background-color: #FFF !important;
}

.pm-form {
  border-radius: 15px !important;
  min-width: 600px !important;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
