<template>
  <div>
    <v-data-table
      :loading="isTableUpdating"
      :headers="headers"
      :items="tableData"
      item-key="id"
      :header-props="{ sortIcon: null }"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :class="{ 'table-updating': isTableUpdating, laptop, laptopSmaller }"
      @click:row="$emit('call-edit-form', $event)"
      @update:page="handleTableOptions"
      @update:sort-by="handleTableOptions('sort-by')"
      @update:sort-desc="handleTableOptions('sort-desc')"
      @update:items-per-page="handleTableOptions"
    >
      <!-- Request Date cell override -->
      <template v-slot:[`item.requestDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Request Time cell override -->
      <template v-slot:[`item.requestTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Client Requester cell override -->
      <template v-slot:[`item.clientRequester`]="{ value }">
        <span>{{
          value ? `${value.user.firstName} ${value.user.lastName}` : ""
        }}</span>
      </template>

      <!-- Delivery Date cell override -->
      <template v-slot:[`item.deliveryDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Delivery Time cell override -->
      <template v-slot:[`item.deliveryTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Item cell override -->
      <template v-slot:[`item.task`]="{ value }">
        <v-tooltip right color="primary" :disabled="value?.length <= 40">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.taskStatus`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :statusMode="true"
          :dropdownOptions="taskStatuses"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>

      <!-- DRI cell override -->
      <template v-slot:[`item.currentDri`]="{ item, value }">
        <span>{{ value ? `${value.firstName} ${value.lastName}` : "" }}</span>
      </template>

      <!-- Assignments cell override -->
      <template v-slot:[`item.preparedAssignments`]="{ item }">
        <div class="d-flex align-center assignmentsCell">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_right_arrow_in_progress'"
                  class="assignmentsCell__icon--item right_arrow_in_progress"
                  :style="item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>In Progress</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_clock'"
                  class="assignmentsCell__icon--item pm_dashboard_clock"
                  :style="item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'In Progress').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>Pending Review</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_pause'"
                  class="assignmentsCell__icon--item right_arrow_in_progress"
                  :style="item.preparedAssignments.find(el => el.status === 'On Hold').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'On Hold').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'On Hold').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>On Hold</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_check_arrow'"
                  class="assignmentsCell__icon--item pm_dashboard_check_arrow"
                  :style="item.preparedAssignments.find(el => el.status === 'Assigned').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'Assigned').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'Assigned').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>Ready For Delivery</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_check'"
                  class="assignmentsCell__icon--item pm_dashboard_check"
                  :style="item.preparedAssignments.find(el => el.status === 'Delivered').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'Delivered').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'Delivered').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>Delivered</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="assignmentsCell__icon"
                  v-bind="attrs"
                  v-on="on">
                <pmdashboard_table_icons
                  :icon="'pmdashboard_close'"
                  class="assignmentsCell__icon--item pm_dashboard_close"
                  :style="item.preparedAssignments.find(el => el.status === 'Cancelled').itemsNumber
                    ? { opacity: 1 }
                    : { opacity: 0.3 }"
                />
                <p class="assignmentsCell__icon--text">
                  {{ 
                    item.preparedAssignments.find(el => el.status === 'Cancelled').itemsNumber
                      ? item.preparedAssignments.find(el => el.status === 'Cancelled').itemsNumber
                      : '-'
                  }}
                </p>
              </div>
            </template>
            <span>Cancelled</span>
          </v-tooltip>
          <div class="assignmentsCell__icon">
            <p class="assignmentsCell__icon--total">total</p>
            <p class="assignmentsCell__icon--text">
              {{ 
                item.preparedAssignments.find(el => el.status === 'Total').itemsNumber
                  ? item.preparedAssignments.find(el => el.status === 'Total').itemsNumber
                  : '0'
              }}
            </p>
          </div>
        </div>
      </template>

      <!-- Note cell override -->
      <template v-slot:[`item.notesToClient`]="{ value }">
        <v-badge overlap color="secondary" :content="value" :value="value">
          <v-icon :disabled="!value" color="accent" medium
            >mdi-message-outline</v-icon
          >
        </v-badge>
      </template>

    </v-data-table>
  </div>
</template>

<script>
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import PMDashboardDropdownSelector from "./PMDashboardDropdownSelector.vue";
import PMDashboardValidationSelector from "./PMDashboardValidationSelector.vue";
//icons
import pmdashboard_table_icons from "@/assets/icons/pmdashboard_table_icons.vue";

export default {
  name: "PMDashboardTable",
  components: {
    PMDashboardDropdownSelector,
    PMDashboardValidationSelector,
    pmdashboard_table_icons
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
    isTableUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      headers: [
        { text: "Task Name", value: "taskName" },
        { text: "Request Date", value: "requestDate" },
        { text: "Request Time", value: "requestTime" },
        { text: "Client Requester", value: "clientRequester", sortable: false },
        { text: "Current DRI", value: "currentDri", sortable: false },
        { text: "Action", value: "action" },
        { text: "Task Status", value: "taskStatus" },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Delivery Time", value: "deliveryTime" },
        { text: "Assignments", value: "preparedAssignments" },
        { text: "Notes To Client", value: "notesToClient", sortable: false },
      ],
      expansionTableData: null,
    };
  },
  mixins: [customBreakpoint],
  methods: {
    handleTableOptions(updatedProp) {
      if (updatedProp === "sort-by" && this.options.sortBy[0] === undefined)
        this.options.sortBy = [...[]];
      else if (
        updatedProp === "sort-desc" &&
        this.options.sortDesc[0] === undefined
      )
        this.options.sortDesc = [...[true]];
      else this.$emit("refresh-request", this.options);
    },
    formatDateTime(date, withtTime, timeOnly) {
      return date
        ? this.$moment(timeOnly ? `2023-12-05 ${date}` : date, timeOnly ? "YYYY-MM-DD hh:mm A" : "").format(
            `${
              timeOnly ? "hh:mm A" : `MM-DD-YYYY${withtTime ? " hh:mm A" : ""}`
            }`
          )
        : "";
    },
    ellipseText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    updateTableData(index, key, value, qcValidation, archiving) {
      this.tableData[index][key] = key === "dri" ? { ...value } : value;
      if (key === "qc") {
        this.tableData[index].validation = qcValidation;
        if (archiving) this.tableData[index].taskStatus = "Archived";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    background-color: #9af9ef70;
    & > tr > th {
      color: #003a35 !important;
      padding: 0 12px !important;
      &:hover {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
  tbody > tr {
    cursor: pointer;
    & > td {
      padding: 0 12px !important;
      &:nth-child(2) {
        pointer-events: none;
      }
    }
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 6px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 6px !important;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px !important;
    }
  }
}
.v-application .text-start {
  text-align: center !important;
}
.table-updating {
  pointer-events: none;
  opacity: 0.5;
}
.assignmentsCell {
  &__icon {
    margin-right: 3px;
    &--item {
      width: 20px;
      height: 20px;
      color: #fff !important;
      border-radius: 50%;
      padding: 3px;
    }
    &--text {
      margin-top: -3px !important;
    }
    &--total {
      background-color: #D9D9D9;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      padding: 0 3px;
    }
    & p {
      margin: 0;
      height: 20px;
    }
  }
  & .right_arrow_in_progress {
    background-color: #7C99E2;
  }
  & .pm_dashboard_clock {
    background-color: #EBCF6D;
  }
  & .pm_dashboard_pause {
    background-color: #EBCF6D;
  }
  & .pm_dashboard_check_arrow {
    background-color: #C5A8EC;
  }
  & .pm_dashboard_check {
    background-color: #70D9A4;
  }
  & .pm_dashboard_close {
    background-color: #FFA1A6;
  }
}
.assignmentsCell .assignmentsCell__icon:last-child {
  margin-top: 1px;
  & .assignmentsCell__icon--text {
    margin-top: 2px !important;
  }
}
</style>
