<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-6">
      <v-container>
        <h2 class="text-center" :class="mode === 'bug' ? 'mb-6' : 'mb-2'">
          {{ title }}
        </h2>
        <h3 v-if="mode !== 'bug' && mode !== 'assignmentManagers'" class="text-center mb-6">
          {{
            mode.charAt(0).toUpperCase() +
            mode.slice(1) +
            " Detail: " +
            (mode === "assignment"
              ? bulkInquiry
                ? "Bulk Inquiry"
                : inquiryItem.assignmentDetails.assignmentDetail
              : inquiryItem?.task?.includes("Shakespeare")
              ? inquiryItem?.task + " / " + inquiryItem.deliveryDate
              : inquiryItem.taskId)
          }}
        </h3>
        <h3 v-if="mode === 'assignmentManagers'" class="text-center mb-6">
          {{"Assignment Detail: " + (inquiryItem.assignmentDetails?.assignmentId
            ? inquiryItem.assignmentDetails?.assignmentId 
            : inquiryItem.assignmentDetails?.assignmentDetail) }}
        </h3>
        <v-row>
          <v-col v-if="mode === 'task' || mode === 'assignmentManagers'" cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Who is this message for:"
              small-chips
              deletable-chips
              multiple
              :loading="loading"
              v-model="messageTarget"
              item-text="value"
              return-object
              :hint="messageTargetHint"
              persistent-hint
              :items="messageTargetsList"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" :sm="mode === 'bug' ? '' : mode === 'task' || mode === 'assignmentManagers' ? 4 : 6" class="pb-0">
            <v-autocomplete
              outlined
              label="Category:"
              v-model="category"
              :disabled="cpmMode"
              :items="categoriesList"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="mode === 'assignment' || mode === 'task' || mode === 'assignmentManagers'" cols="12" :sm="mode === 'task' || mode === 'assignmentManagers' ? 4 : 6" class="pb-0">
            <v-autocomplete
              outlined
              label="Urgency of the request:"
              v-model="urgency"
              item-text="value"
              return-object
              :items="urgencyList"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              outlined
              clearable
              rows="1"
              clear-icon="mdi-close-circle-outline"
              label="Please describe the issue."
              v-model="question"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-end pb-6">
      <v-btn width="20%" color="secondary" @click="handleSubmit">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { processAssignment, getAllStaff } from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import { DOMAIN } from "@/utils/constants";

export default {
  name: "SlackInquiryForm",
  props: {
    mode: {
      type: String,
      required: true,
    },
    inquiryItem: {
      type: Object,
      required: false,
    },
    leadPMSlack: {
      type: String,
      required: false,
    },
    bulkInquiry: {
      type: Boolean,
      required: false,
    },
    cpmMode: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      question: "",
      messageTarget: [],
      messageTargetsList: [
        { value: "FC PM Team", ping: "<!channel>" },
      ],
      category: "",
      urgency: { value: "Not Urgent", emoji: ":white_circle:" },
      urgencyList: [
        { value: "Not Urgent", emoji: ":white_circle:" },
        { value: "Timely, but Not Urgent", emoji: ":large_blue_circle:" },
        { value: "Urgent", emoji: ":red_circle:" },
      ],
      valid: true,
      staff: [],
      adminManagerStaff: [],
      loading: true,
      rules: {
        required: (value) => !!value || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  watch: {
    inquiryItem(newVal) {
      if (newVal) {
        this.question = "";
        this.handleCategory();
        this.messageTarget = {};
        this.urgency = this.urgencyList[0];
        this.$refs.form.resetValidation();
      }
    },
  },
  async mounted() {
    this.handleCategory();
    if (this.mode === "task" || this.mode === "assignmentManagers") {
      this.staff = await getAllStaff();
      const adminManagerStaff = this.staff.filter(
        (staff) => staff.user.userRole === "ADMIN" || staff.user.userRole === "MANAGER"
      )
      this.adminManagerStaff = adminManagerStaff.map(el => { return { value: el.user.firstName + " " + el.user.lastName, ping: `<@${el.adminDetails.slackIdFc}>` } });
      this.adminManagerStaff.sort((a, b) => a.value.localeCompare(b.value));
      this.messageTargetsList.push(...this.adminManagerStaff);
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    title() {
      if (this.mode === "assignment") return "ResCue Me!";
      if (this.mode === "task") return "Project Manager Hotline";
      if (this.mode === "bug") return "CueHub Feedback";
      if (this.mode === "assignmentManagers") return "Project Manager Hotline";
    },
    categoriesList() {
      if (this.mode === "assignment") {
        let categories = [
          "Editorial Question",
          "Missing File",
          "Tool/Platform-Related Issue",
          "Deadline-Related Issue",
          "Note to Reviewer/Client",
          "Invoice-Related Issue",
          "Other",
        ];
        if (this.$route.fullPath.includes("Shakespeare=0"))
          categories.unshift("Task Reassignment");
        return categories;
      } else if (this.mode === "task" || this.mode === "assignmentManagers") {
        return [
          "Billing", 
          "Client Correspondence in Progress",
          "Deadline Issue",
          "Resource Issue",
          "Possible Error",
          "Manually Processed Task",
          "Other"
        ];
      } else if (this.mode === "bug") {
        return ["Bug Report", "Feature Suggestion"];
      } else {
        return [];
      }
    },
    messageTargetHint() {
      return this.messageTarget?.note ?? "";
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleCategory() {
      this.category = this.cpmMode ? "Invoice-Related Issue" : "";
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        publishMessage({
          user: this.user.adminDetails.slackIdFc
            ? `<@${this.user.adminDetails.slackIdFc}>`
            : `*${this.user.user.firstName} ${this.user.user.lastName}*`,
          mode:
            this.category === "Task Reassignment"
              ? "reassignment"
              : this.category === "Invoice-Related Issue"
              ? "invoice"
              : this.mode,
          data: {
            question: this.question,
            urgency: this.urgency,
            category: this.category,
            messageTarget: this.messageTarget,
            link: `${DOMAIN}${this.$route.fullPath}`,
          },
          item: this.inquiryItem,
          bulkInquiry: this.selectedDashboardRows,
        });
        if (this.category === "Note to Reviewer/Client")
          processAssignment({
            data: {
              noteToClient: `${this.inquiryItem.assignmentDetails.taskId} / ${
                this.inquiryItem.assignmentDetails.assignmentTitle
              } / ${this.inquiryItem.assignmentDetails.assignmentDetail} / ${
                this.inquiryItem.assignmentDetails.geo
              } / ${this.question} / ${this.user.user.firstName} ${
                this.user.user.lastName
              } at ${this.$moment(
                new Date().toLocaleString("en-US", {
                  timeZone: "America/New_York",
                })
              ).format("MM-DD-YYYY hh:mm A")}`,
            },
            id: this.inquiryItem.id,
            patch: true,
          })
            .then((resp) => {
              if (resp?.status > 204)
                this.handleFlash({
                  response: resp,
                  show: true,
                });
            })
            .catch((err) => {
              console.error(err);
              this.handleFlash({
                response: err,
                show: true,
              });
            });
        this.$emit("close-slack-form");
        this.handleFlash({
          response: { data: { statusCode: 200 } },
          show: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
